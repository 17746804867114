// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('@hotwired/turbo-rails')
require('form-request-submit-polyfill')

import jQuery from 'jquery'
global.$ = jQuery
global.jQuery = jQuery

import objectFitImages from 'object-fit-images'
import tippy from 'tippy.js'
window.tippy = tippy
tippy.setDefaults({
  animation: 'fade',
  arrow: true,
})

// lazy load
import LazyLoad from 'vanilla-lazyload'
const lazyLoadOptions = { elements_selector: '.lazy' }
window.pageLazyLoad = new LazyLoad(lazyLoadOptions)

document.addEventListener('turbo:load', function () {
  tippy('[data-tippy-content]')

  objectFitImages()

  // trustpilot
  const trustbox = document.getElementById('trustbox')
  if (trustbox != null && window.Trustpilot) {
    window.Trustpilot.loadFromElement(trustbox)
  }

  // hubspot
  if (!document.getElementById('hubspot-messages-iframe-container') && window.HubSpotConversations) {
    window.HubSpotConversations.resetAndReloadWidget()
  }
  
  try {
    _ictt._pplr()
  } catch (e) {}

  setTimeout(function () {
    document.body.classList.remove('css-transitions-only-after-page-load')

    document.querySelectorAll('.unset-size-params').forEach(function(el) {
      el.style.height = null;
      el.style.width = null;
    })
  }, 10);
})

document.addEventListener('turbo:render', function () {
  window.pageLazyLoad.update()
})

// add images
const images = require.context('../images', true)

import '../src/javascripts'
