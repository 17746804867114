document.addEventListener('turbo:load', function () {
  $('body').on('click', '.contact-support', function (e) {
    // get reason and assign
    var reason = $(e.currentTarget).data('support-reason')

    if (typeof window.HubSpotConversations != 'undefined') {
      window.history.pushState({}, 'hs_bot', '?hs_bot=true');
      window.HubSpotConversations.widget.refresh({openToNewThread: true});
      setTimeout(function() {
        window.HubSpotConversations.widget.open();
      }, 500);
    } else {
      window.location.href = "mailto:support@wearewildgoose.com?subject=" + encodeURI(reason || "");
    }

    e.preventDefault()
    return false
  })

  $('body').on('click', '.email-support', function (e) {
    // get reason and assign
    var reason = $(e.currentTarget).data("support-reason")

    window.location.href = "mailto:support@wearewildgoose.com?subject=" + encodeURI(reason || "");

    e.preventDefault();
    return false;
  })

  if (typeof (window.HubSpotConversations) == "undefined") {
    $(".live-chat-option").hide();
  }
})
