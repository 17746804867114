document.addEventListener('turbo:load', function () {
  window.hiddenScrollElements = false
  $(document).on('scroll', function () {
    if (!window.hiddenScrollElements) {
      $('.icon-scroll').fadeOut()
    }
  })

  $('.form-scroll').on('click', function (e) {
    $('html, body').animate(
      {
        scrollTop: $('.page-form-scroll').offset().top - 100,
      },
      700
    )

    e.preventDefault()
    return false
  })

  $('.pricing-calculator-scroll').on('click', function (e) {
    $('html, body').animate(
      {
        scrollTop: $('.page-pricing-calculator-scroll').offset().top - 100,
      },
      700
    )

    e.preventDefault()
    return false
  })
})
