window.addEventListener('turbo:load', function () {
  function configureAutocompleteInputs() {
    $('#env-google-api').remove()

    $('.new_partner_enquiry')
      .off('keypress')
      .on('keypress', function (e) {
        if (e.keyCode == 13) {
          return false
        }
      })

    $('.location-select').each(function () {
      var input = this
      if ($(input).hasClass('core-icon')) {
        return
      }
      var autocomplete = new google.maps.places.Autocomplete(input, {
        componentRestrictions: { country: 'gb' },
      })
      google.maps.event.addListener(autocomplete, 'place_changed', function () {
        var place = this.getPlace()
        var components = place.address_components
        console.log(components)

        ResetFields()
        FillAddressFields(components)
        ValidateFields()
      })
    })
  }

  configureAutocompleteInputs()
})

function ResetFields() {
  $('.manual-address-fields input').each(function () {
    $(this).val('')
  })
}

function FillAddressFields(components) {
  $(components).each(function () {
    if (this.types.includes('street_number')) {
      $('#partner_enquiry_address_line_1').val(this.long_name)
    } else if (this.types.includes('route')) {
      $('#partner_enquiry_address_line_2').val(this.long_name)
    } else if (this.types.includes('postal_town')) {
      $('#partner_enquiry_address_town').val(this.long_name)
    } else if (this.types.includes('administrative_area_level_2')) {
      $('#partner_enquiry_address_county').val(this.long_name)
    } else if (this.types.includes('country')) {
      $('#partner_enquiry_address_country').val(this.long_name)
    } else if (this.types.includes('postal_code')) {
      $('#partner_enquiry_address_postcode').val(this.long_name)
    }
  })
}

function ValidateFields() {
  var validation = $('.validation')
  var message = []
  $('.manual-address-fields input').each(function () {
    if (!$(this).val()) {
      message.push($(this).attr('placeholder'))
    }
  })

  if (message.length) {
    message = message.join(', ')
    message = 'Please fill ' + message
    message = message.replace(/,(?=[^,]*$)/, ' and')
    $('.manual-address-fields').removeClass('hidden')
    $('.automatic-address-field').addClass('hidden')
    $('[data-action="click->demo#show_manual"]').text(
      'Hide manual address entry'
    )
    validation.removeClass('hidden')
  }
  validation.text(message)
}
