$(document).on('click', '.js-manage-event', function (e) {
  e.preventDefault()
  const event = new CustomEvent('open-manage-modal')
  window.dispatchEvent(event)
})

$(document).on('click', '.js-need-help', function (e) {
  e.preventDefault()
  const event = new CustomEvent('open-need-help-modal')
  window.dispatchEvent(event)
})

$(document).on('click', '.js-presentations-modal', function (e) {
  e.preventDefault()
  const event = new CustomEvent('open-presentations-modal')
  window.dispatchEvent(event)
})
