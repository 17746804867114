// load in AJAX

$(document).on('turbo:load', function () {
  $('span.right-aligned-image').each(function (i, el) {
    wrap(el, 'right-aligned-image-wrapper')
    el.parentElement.remove()
  })

  $('span.left-aligned-image').each(function (i, el) {
    wrap(el, 'left-aligned-image-wrapper')
    el.parentElement.remove()
  })

  function wrap(el, klass) {
    el = el.parentElement

    var wrapper = document.createElement('div')
    wrapper.className = klass

    var leftWrapper = document.createElement('div')
    wrapper.appendChild(leftWrapper)
    el.parentNode.insertBefore(wrapper, el)

    var paraSiblings = []
    var imgSibling
    var search = true

    while (search) {
      el = el.nextElementSibling

      if (!el) {
        search = false
      } else if (el.classList.contains('block-img')) {
        imgSibling = el
        search = false
      } else {
        paraSiblings.push(el)
      }
    }

    for (var i = 0; i < paraSiblings.length; i++) {
      leftWrapper.appendChild(paraSiblings[i])
    }
    if (imgSibling) {
      wrapper.appendChild(imgSibling)
    }
  }
})
