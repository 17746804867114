// load in AJAX

$(document).on('turbo:load', function () {
  $("*[rel='modal:ajax']")
    .off('click')
    .on('click', function (e) {
      const modal = $('.modal-holder')
      const modalBody = modal.find('.modal-body')
      modalBody.addClass('hidden')
      modal.addClass('active')
      $('body').addClass('modal-shown')

      $.ajax({
        url: $(this).attr('href'),
        success: function (modalHTML, textStatus, jqXHR) {
          modal.html(modalHTML)
          modalBody.removeClass('hidden')
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          modal.removeClass('active')
          $('body').removeClass('modal-shown')
          alert('Error whilst loading page.')
        },
      })

      e.preventDefault()
    })
})
