import Headroom from 'headroom.js'

document.addEventListener('turbo:load', function () {
  // if you want to make a navbar sticky using headroom, add a "sticky-navbar" class to your div

  // grab an element
  const header = $('body').find('.sticky-navbar')[0]
  if (!header) {
    return
  }
  if (window.matchMedia('(max-width: 767px)').matches) {
    return
  }

  let headroom = new Headroom(header, {
    // any configuration?
    tolerance: 10,
  })

  headroom.init()
})
